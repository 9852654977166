<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="768"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            Selecione abaixo os contratos e dependentes que deseja reativar
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeModal(false)">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <div
            v-for="item in model"
            :key="item.contractId"
            class="card-reactivate"
          >
            <v-checkbox
              hide-details
              class="mt-0 pa-0"
              :label="item.contractName"
              v-model="item.checked"
            />

            <v-simple-table dense v-if="item.checked && item.dependents.length">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Nome do dependente</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="dependent in item.dependents"
                    :key="dependent.dependentId"
                  >
                    <td>
                      <v-checkbox
                        dense
                        hide-details
                        color="primary"
                        class="mt-0 pt-0"
                        :label="dependent.dependentName"
                        v-model="dependent.checked"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-card-actions class="b-dialog--actions">
          <v-spacer />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    employeeId: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: []
  }),

  created() {
    this.search();
  },

  methods: {
    closeModal(value) {
      this.$emit('closeModal', value);
    },

    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } =
          await movementService.getContractsForReactivation(this.employeeId);

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async save() {
      try {
        const contractSelected = this.model.filter(x => x.checked);

        if (contractSelected.length === 0) {
          showMessage(
            'error',
            'Selecione pelo menos 1 contrato para prosseguir'
          );
          return;
        } else {
          const movementService = new MovementService();
          const { status } = await movementService.putContractsForReactivation({
            employeeId: this.employeeId,
            contracts: this.model
          });

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.closeModal(true);
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>

<style lang="scss">
.card-reactivate {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;
  padding: 12px;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.theme--dark {
  .card-reactivate {
    background: $bg-dark;
    box-shadow: 0px 0px 2px 1px $shadow-dark;
  }
}

.theme--light {
  .card-reactivate {
    background: $bg-light;
    box-shadow: 0px 0px 2px 1px $shadow-light;
  }
}
</style>
